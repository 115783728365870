/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {
  Box,
  Text,
  Label,
  Input,
  Inline,
  Stack,
  Button
} from '@metrewards/react-components';

import { FormCard } from '../form-card';

function EmailCard() {}
function ConfirmCard() {}
function UpdateEmailCard({
  onEmailChange,
  onEmailButtonClick,
  emailButtonDisabled,
  onConfirmCodeChange,
  onConfirmCodeButtonClick,
  confirmButtonDisabled,
  tabKey,
  setTabKey,
  emailOK
}) {
  return (
    <FormCard
      header={
        <Box display="flex" alignItems="center">
          <Box
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="currentColor"
            size={16}
            color="primary"
          >
            <Box
              as="path"
              id="account-solid-b"
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            />
          </Box>
          <Text fontWeight="bold" sx={{ ml: 4 }}>
            Update Email
          </Text>
        </Box>
      }
      content={
        <>
          {tabKey === 'step1' && (
            <Stack space={4}>
              <Stack space={2}>
                <Label
                  fontSize={1}
                  fontWeight="bold"
                  description="Step 1 of 2 | Enter your new email address"
                >
                  Update Email Address
                </Label>
                <Input
                  placeholder="email@address.com"
                  type="email"
                  onChange={onEmailChange}
                />
              </Stack>
              <Text fontSize={1} color="gray.500">
                A confirmation code will be emailed to this new address
              </Text>
              <Button
                disabled={!emailOK || emailButtonDisabled}
                onClick={onEmailButtonClick}
              >
                {emailButtonDisabled
                  ? 'Checking...'
                  : emailOK
                  ? 'Confirm Email Change'
                  : 'Enter Valid Email'}
              </Button>
            </Stack>
          )}
          {tabKey === 'step2' && (
            <Stack space={4}>
              <Stack space={2}>
                <Label
                  fontSize={1}
                  fontWeight="bold"
                  description="Step 2 of 2 | Enter your confirmation code (check your inbox)"
                >
                  Update Email Address
                </Label>

                <Input
                  placeholder="eg. 123456"
                  type="number"
                  onChange={onConfirmCodeChange}
                />
              </Stack>
              <Inline space={4}>
                <Button
                  disabled={confirmButtonDisabled}
                  onClick={onConfirmCodeButtonClick}
                >
                  {confirmButtonDisabled
                    ? 'Checking...'
                    : 'Verify Confirmation Code'}
                </Button>
                <Button variant="link" onClick={() => setTabKey('step1')}>
                  Go back
                </Button>
              </Inline>
            </Stack>
          )}
        </>
      }
    />
  );
}

export { UpdateEmailCard };
